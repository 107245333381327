import React from "react";
import { useLocation } from "react-router-dom";
import logo from "../images/logo.svg";


function Navbar(props) {
    const location = useLocation();

    return (
        <div className="Navbar">
            <nav className="navbar navbar-light">
                <div className="container-fluid container-color">
                    <img src={logo} alt="" className="navbar-logo" />
                    {
                        location.pathname == '/home' ?
                            <div className="col col-sm-4">
                                <input className="form-control"
                                    onChange={(event) => props.setSearchValue(event.target.value)} value={props.value} />
                            </div> : null
                    }
                    <button className="navbar-btn btn btn-outline-secondary">Login</button>
                </div>
            </nav>
        </div>
    )
}

export default Navbar;