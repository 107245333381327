import React from "react";
import Maincard from "./Maincard";
import Moviecard from "./Moviecard";
import Slideshow from "./Slideshow";

function Home(props) {
    var homeData = props.data;
    var homSearchData = props.searchValue;
   
    return (
        <>
            <div className="container-fluid container-color">
                <div className="home-body">
                    <Slideshow />
                    <Maincard />
                    <Moviecard homeData={homeData} homSearchData={homSearchData}/>
                </div>
            </div>
        </>
    )

}

export default Home;