//import Lern from './Lern';
import React, { useEffect, useRef } from "react";

// function User() {
//     return (
//         <div className="App">
//             <h1>Hello welcome to react</h1>
//             <Lern name={"kiran"}/>
//             {/* <Lern name={"rasmin"}/>
//             <Lern name={"mehul"}/> */}
//         </div>
//     );
//   }
  

function User(props) {
    let lastVal = useRef();
    useEffect(()=>{
        lastVal.current = props.count
    })

    let previosProps = lastVal.current
    return (
        <div className="App">
            <h1>User current Value : {props.count}</h1>
            <h1>User Previos Value : {previosProps}</h1>
            
        </div>
    );
}

export default User;