import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";

function Recommend(props) {

    const movieSearchData = props.movieSearchData
    const movieData = props.moviedata;
    const url = "https://image.tmdb.org/t/p/w220_and_h330_face/"

    return (
        <>
            <div className="container-fluid container-color">
                <div className="row">
                    <h4>Recommended For You</h4>

                    {
                        movieData?.results?.filter(movie => {
                            if (movieSearchData === '') {
                                return movie;
                            } else if (movie.title.toLowerCase().includes(movieSearchData.toLowerCase())) {
                                return movie;
                            }
                        }).map((movie, index) => (
                            <div className="col-lg-3 col-md-6 col-6">
                                <div className="card movie-card">
                                    <Link to={`/detail/${movie.id}`}>
                                        <img src={url + movie.poster_path} className="card-img-top" al="..." id={movie.id} />
                                    </Link>

                                </div>
                            </div>
                        ))
                    }

                </div>
            </div>
        </>
    )
}

export default Recommend;