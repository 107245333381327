import logo from './logo.svg';
import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import Navbar from './components/Navbar';
import Login from './components/Login';
import Home from './components/Home';
import Details from './components/Details';


function App() {
    
    const pathname = window.location.pathname ;

    const [data, setData] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    const url = "https://image.tmdb.org/t/p/w220_and_h330_face/";
    useEffect(() => {
        fetch(`https://api.themoviedb.org/3/movie/popular?api_key=eca6e2646ba60ae5c66165b1b689c91f&language=en-US`).then((result) => {
            result.json().then((resp) => {
                setData(resp);
            })
        })
    }, [])


    return (
        <>
        <div>
        
        </div>
            <BrowserRouter>
            <Navbar searchValue={searchValue} setSearchValue={setSearchValue} />
                <Routes>
                    <Route path='/' element={<Login/>} />
                    <Route path='/home' element={<Home searchValue={searchValue} data={data} />} />
                    <Route path='/detail/:id' element={<Details data={data} />}>
                        
                    </Route>
                    
                </Routes>
            </BrowserRouter>
        </>
    );
}




export default App; 
