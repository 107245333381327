import React from "react";
import Newarriwal from "./Newarriwal";
import Originals from "./Originals";
import Recommend from "./Recommend";
import Trending from "./Trending";



function Moviecard(props)
{
    var moviedata = props.homeData;
    var movieSearchData = props.homSearchData;
    return(
        <>
            <Recommend moviedata={moviedata} movieSearchData={movieSearchData}/>
            
        </>
    )   
}

export default Moviecard;